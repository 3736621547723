<template>
  <div class="container">
    <div class="input-container">
      <el-input
          v-model="inputValue"
          type="textarea"
          :rows="4"
          placeholder="请输入文件地址，每行一个"
          style="width: 80vw;"
          size="medium"
      ></el-input>
    </div>
    <div class="button-container">
      <el-button type="success" icon="el-icon-edit-outline" @click="pushTable">加入队列</el-button>
      <el-button type="success" icon="el-icon-circle-close" @click="clearTable">清空队列</el-button>
      <el-button type="success" icon="el-icon-edit-outline" @click="optionVisible = true">选项</el-button>
      <el-button type="primary" icon="el-icon-video-play" :disabled="loading || this.tableData.length <= 0" :loading="loading" @click="startParseAsync">开始</el-button>
      <el-button type="warning" icon="el-icon-video-pause" :disabled="!loading" @click="stopParse">停止</el-button>
    </div>
    <el-dialog
        title="选项"
        :visible.sync="optionVisible"
        width="30%"
        >
      <el-checkbox v-model="autoFormatLineContent">加入队列时自动格式化每行内容</el-checkbox><br >
      <el-checkbox disabled v-model="asyncParse">异步多线程解析</el-checkbox><br >
      <el-checkbox v-model="autoDownload">解析成功自动下载</el-checkbox><br >
      <el-radio v-model="downloadType" label="0">解析成功自动推送RPC下载</el-radio><br >
      <el-radio v-model="downloadType" label="1">解析成功自动推送迅雷下载</el-radio><br >
      <el-radio disabled v-model="downloadType" label="2">解析成功自动推送IDM下载</el-radio><br >
      <el-checkbox v-model="autoFileSuffix">自动后缀</el-checkbox><br >
      <el-input v-model="fileSuffix" placeholder="请输入后缀,包括. 例如.rar"></el-input><br >
      <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="optionVisible = false">确 定</el-button>
  </span>
    </el-dialog>
    <div class="divider"></div>
    <div class="table-container">
      <el-table height="55vh" :data="tableData" style="width: 80vw; height: 55vh">
        <el-table-column prop="id" label="ID"></el-table-column>
        <el-table-column prop="url" label="文件地址"></el-table-column>
        <el-table-column prop="name" label="文件名"></el-table-column>
        <el-table-column prop="domain" label="对应网盘"></el-table-column>
        <el-table-column prop="status" label="状态">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.code == 0" type="info" effect="plain">{{scope.row.status}}</el-tag>
            <el-tag v-if="scope.row.code == 1" type="success" effect="plain">{{scope.row.status}}</el-tag>
            <el-tag v-if="scope.row.code == -1" type="danger" effect="plain">{{scope.row.status}}</el-tag>
            <el-tag v-if="scope.row.code == -3" type="warning" effect="plain">{{scope.row.status}}</el-tag>
            <el-tag v-if="scope.row.code == -999" type="danger" effect="plain">{{scope.row.status}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
            fixed="right"
            label="操作"
            >
            <template slot-scope="scope">
              <el-button
                  size="mini"
                  @click="reDownload(scope.$index, scope.row)">下载</el-button>
              <el-button
                  size="mini"
                  type="danger"
                  :loading="loading"
                  :disabled="loading"
                  @click="deleteRow(scope.$index, scope.row)">删除</el-button>
            </template>

        </el-table-column>
      </el-table>
      <el-dialog
          title="下载"
          :visible.sync="downloadDialogVisible"
          width="50%"
          center
      >
        <el-button @click="Download(1)" type="success" plain>RPC下载</el-button>
        <el-button @click="Download(0)" type="primary" plain>迅雷下载</el-button>
        <el-button @click="Download(2)" type="info" plain>IDM下载</el-button>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      inputValue: '',
      loading: false,
      tableData: [

      ],
      parseList:[

      ],
      isStop: false,
      optionVisible: false,
      autoFormatLineContent: true,
      autoDownloadByAria: true,
      autoDownloadByThunder: false,
      autoDownloadByIDM: false,
      asyncParse: false,
      downloadType: '0',
      autoDownload: true,
      downloadDialogVisible: false,
      nowSelectItem:{},
      fileSuffix: '',
      autoFileSuffix: false
    };
  },
  methods: {
    pushTable(){
      this.$message.warning('该页面正在施工中....')
    },
    clearTable(){
      this.$message.warning('该页面正在施工中....')
    },
    startParse() {
      this.$message.warning('该页面正在施工中....')
    },
    async startParseAsync() {
      this.$message.warning('该页面正在施工中....')
    },
    stopParse() {
      this.$message.warning('该页面正在施工中....')
    },
    reDownload(index, e){
      this.$message.warning('该页面正在施工中....')
    },
    deleteRow(index, e){
      this.$message.warning('该页面正在施工中....')
    },
    Download(type){
      this.$message.warning('该页面正在施工中....')
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.input-container {
  margin-top: 20px;
}

.button-container {
  margin-top: 20px;
}

.divider {
  margin: 20px 0;
  width: 100%;
  border-bottom: 1px solid #ccc;
}

.table-container {
  margin-top: 20px;
}
</style>
